var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-animate",on:{"mouseover":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false}}},[_c('router-link',{attrs:{"to":{
                    name : 'Media',
                    params : {
                      id : _vm.media.media_id,
                      wistiaId : _vm.media.wistia_id,
                    }
                 }}},[_c('div',{attrs:{"title":_vm.media.media_title}},[_c('img',{staticClass:"img-fluid mx-auto d-block card-img-top media-thumbnail",attrs:{"src":`${_vm.media.thumbnail}`,"alt":"media-thumbnail"}}),(_vm.loading && _vm.hovering)?_c('span',{staticClass:"fas fa-spinner fa-spin image-loading"}):_vm._e(),_c('div',{staticClass:"card-body"},[_c('p',[(_vm.breakpoint == 'xs' || _vm.windowWidth >= 1600)?[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.media.media_title,45))+" ")]:[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.media.media_title))+" ")]],2),_c('p',[_c('i',{staticClass:"far fa-clock"}),_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.media.media_length))+" ")])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }