<template>
  <div>
    <div class="card card-animate"
         @mouseover="hovering = true"
         @mouseleave="hovering = false"
    >
      <router-link :to="{
                      name : 'Media',
                      params : {
                        id : media.media_id,
                        wistiaId : media.wistia_id,
                      }
                   }">
        <div :title="media.media_title">
          <img class="img-fluid mx-auto d-block card-img-top media-thumbnail"
               :src="`${media.thumbnail}`"
               alt="media-thumbnail"
          />
          <span class="fas fa-spinner fa-spin image-loading"
                v-if="loading && hovering"/>
          <div class="card-body">
            <p>
              <template v-if="breakpoint == 'xs' || windowWidth >= 1600">
                {{ media.media_title | truncate(45) }}
              </template>
              <template v-else>
                {{ media.media_title | truncate }}
              </template>
            </p>
            <p>
              <i class="far fa-clock"></i>
              {{ media.media_length | formatTime }}
            </p>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    props : {
      media : Object,
    },
    data() {
      return {
        maxImages  : 3,
        hovering   : false,
        loading    : true,
        graceCount : 3,
        imageIndex : 0,
        images     : [],
        interval   : null,
      };
    },
    watch : {
      hovering() {
        const imgElement = this.$el.querySelector('.media-thumbnail');
        if (this.hovering) {
          this.interval = setInterval(() => {
            imgElement.src = this.images[this.imageIndex];
            this.imageIndex = this.imageIndex < (this.maxImages - 1) ?
              this.imageIndex + 1 : 0;

            if (this.graceCount > 0)
              this.graceCount--;
            else {
              if (this.loading)
                this.loading = false;
            }
          }, 900);
        } else {
          imgElement.src = this.media.thumbnail;
          clearInterval(this.interval);
        }
      },
    },
    computed : {
      thumbnailUrl() {
        const stillIndex =
          this.media.orig_thumbnail.indexOf('&video_still_time');
        const cropped = this.media.orig_thumbnail.substring(0, stillIndex) +
          '&video_still_time=';
        return cropped;
      },
    },
    mixins : [
      FilterMixin,
      BreakpointMixin,
    ],
    mounted() {
      this.images = [];
      this.getImages();
    },
    methods : {

      /**
       * Get images for animated thumbnails
       */
      getImages() {
        const max = Math.floor(this.media.media_length);

        for (let i = 0; i < this.maxImages; i++) {
          this.images.push(
            `${this.thumbnailUrl}${this.randomIntFromInterval(1, max)}`
          );
        }
      },

      /**
       * Get random integer from interval
       *
       * @param min
       * @param max
       * @return {number}
       */
      randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      },

    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/common/app-media-list";
</style>
